import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);

    let [visorVisibility, setVisorVisibility] = useState("hiddenModal");
    let [imgSrc, setImgSrc] = useState("");
    let [visorComments, setVisorComments] = useState("");
    let [imgDisplay, setImgDisplay] = useState("none");
    let [pdfSrc, setPdfSrc] = useState("");
    let [pdfDisplay, setPdfDisplay] = useState("none");

    //Configurables---------------------------------------------------------------------------
    let { patientId } = useParams();
    let titulo = "Ordenes";
    if (patientId) {
        titulo = new URLSearchParams(window.location.search).get('name') ?? 'Ordenes';
    }

    const icono = "fa-file";
    const modals = [
        {
            titulo: "Nueva Sede",
            endpoint: "branch",
            buttonText: "Guardar",
            urlCall: "new",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombre", name: "name", element: "input", type: "text", required: true, isNumber: false }
                    ]
                }
            ],
            dataMask: null
        },
        {
            titulo: "Editar Sede",
            endpoint: "branch",
            buttonText: "Editar",
            urlCall: "edit",
            method: "PUT",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombre", name: "name", element: "input", type: "text", required: true, isNumber: false }
                    ]
                }
            ],
            dataMask: null
        }
    ];
    let tabla1;
    if (infoUsuario.role.name == "ADMIN" || infoUsuario.role.name == "EMPLOYEE") {
        tabla1 = {
            endpoint: "order/admin/view2",
            titulos: [
                { texto: "Codigo", name: "code" },
                { texto: "Tipo", name: "code" },
                { texto: "Proceso", name: "code" },
                { texto: "Estado", name: "statusId" },
                { texto: "Fecha de Creación", name: "created_at" },
                { texto: "Nombres", name: "patient" },
                { texto: "Apellidos", name: "patient" },
                { texto: "Clave", name: "patient" },
                { texto: "Tipo Documento", name: "name" },
                { texto: "Numero Documento", name: "name" },
                { texto: "Clinica", name: "tradename" },
                { texto: "Doctor", name: "name" },
                { texto: "Sede", name: "name" },
                { name: "status" }
            ],
            columnas: [
                { prop: "order", nested: 'code', type: "link", url: "/ordenes/$orderId$" },
                { prop: "fileType", nested: 'name', type: "link", url: "/ordenes/$orderId$/examen/$id$" },
                { prop: "currentActiveProcess", type: "link", url: "/ordenes/$orderId$/examen/$id$", format: (x) => { return x ? x.name : '-' } },
                { prop: "order", nested: 'statusId', type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; console.log(estados[x - 1]); return estados[x - 1]; } },
                { prop: 'order', nested: "created_at", type: "link", url: "/ordenes/$orderId$", format: (x) => { return x.split("T")[0]; } },
                { prop: "order", type: "text", format: (x) => { return x.patient.firstName } },
                { prop: "order", type: "text", format: (x) => { return x.patient.lastName } },
                { prop: "order", type: "text", format: (x) => { return (x.patient.firstName[0] + x.patient.documentNumber.substr(x.patient.documentNumber.length - 3)).toUpperCase() } },
                { prop: "order", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.patient.documentTypeId })[0].text } },
                { prop: "order", type: "text", format: (x) => { return x.patient.documentNumber } },
                { prop: "order", type: "text", format: (x) => { if (x.client === null) { return ""; } if (x.client.tradename != "") { return x.client.tradename; } else { return `${x.client.firstName} ${x.client.lastName}`; } } },
                { prop: "order", type: "text", format: (x) => { if (x.doctor === null) { return ""; } else { return x.doctor.firstName + " " + x.doctor.lastName; } } },
                { prop: "order", type: "text", format: (x) => { if (x.branch == null) { return ""; } else { return x.branch.name; } } },
                { type: "status", url: "order", nested: 'order', customId: 'orderId', statusOrder: true }
            ]
        }
        // tabla1 = {
        //     endpoint: "order",
        //     titulos: [
        //         { texto: "Codigo", name: "code" },
        //         { texto: "Tipo", name: "code" },
        //         { texto: "Proceso", name: "code" },
        //         { texto: "Estado", name: "statusId" },
        //         { texto: "Fecha de Creación", name: "created_at" },
        //         { texto: "Nombres", name: "patient" },
        //         { texto: "Apellidos", name: "patient" },
        //         { texto: "Clave", name: "patient" },
        //         { texto: "Tipo Documento", name: "name" },
        //         { texto: "Numero Documento", name: "name" },
        //         { texto: "Clinica", name: "name" },
        //         { texto: "Doctor", name: "name" },
        //         { texto: "Sede", name: "name" },
        //         { name: "status" }
        //     ],
        //     columnas: [
        //         { prop: "code", type: "link", url: "/ordenes/$id$" },
        //         {
        //             prop: "code", type: "text", text: "TIPO DE EXAMEN", format: (x) => {
        //                 const arr = ['MODELO STL', 'ATM', 'TOMOGRAFÍA', 'PERIAPICAL', 'PANORÁMICA', 'ESTUDIO FOTOGRÁFICO', 'MAXODENTAL', 'ESTRRUDENTAL', 'RX ESTRIPOFÁXICO'];
        //                 return arr[(Math.floor(Math.random() * arr.length))]
        //             }
        //         },
        //         {
        //             prop: "code", type: "boldText", text: "TIPO DE EXAMEN", format: (x) => {
        //                 const arr = ['-', 'PASO 1', 'PASO 2', 'PASO 3', 'PASO 4', 'PASO 5', 'PASO 6', 'PASO 7', 'PASO 8',];
        //                 return arr[(Math.floor(Math.random() * arr.length))]
        //             }
        //         },
        //         { prop: "statusId", type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; return estados[x - 1]; } },
        //         { prop: "created_at", type: "link", url: "/ordenes/$id$", format: (x) => { return x.split("T")[0]; } },
        //         { prop: "patient", type: "text", nested: "firstName" },
        //         { prop: "patient", type: "text", nested: "lastName" },
        //         { prop: "patient", type: "text", format: (x) => { return (x.firstName[0] + x.documentNumber.substr(x.documentNumber.length - 3)).toUpperCase() } },
        //         { prop: "patient", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.documentTypeId })[0].text } },
        //         { prop: "patient", type: "text", nested: "documentNumber" },
        //         { prop: "client", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.tradename; } else { return `${x.firstName} ${x.lastName}`; } } },
        //         { prop: "doctor", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.firstName + " " + x.lastName; } else { return x.firstName + " " + x.lastName; } } },
        //         { prop: "branch", type: "text", format: (x) => { if (x == null) { return ""; } else { return x.name; } } },
        //         { type: "status", url: "order", statusOrder: true }
        //     ]
        // }
    } else if (infoUsuario.role.name == "PATIENT" || infoUsuario.role.name == "DOCTOR" || infoUsuario.role.name == "CLINIC") {
        tabla1 = {
            endpoint: "order/patient/results",
            titulos: [
                // { texto: "Codigo", name: "code" },
                // { texto: "Estado", name: "statusId" },
                { texto: "Fecha de Creación", name: "created_at" },
                { texto: "Tipo", name: "fileType" },
                { texto: "Nombre", name: "filename" },
                // { texto: "Apellidos", name: "patient" },
                // { texto: "Clave", name: "patient" },
                // { texto: "Tipo Documento", name: "name" },
                // { texto: "Numero Documento", name: "name" },
                // { texto: "Clinica", name: "name" },
                // { texto: "Doctor", name: "name" },
                // { texto: "Sede", name: "name" },
                // { name: "status" }
                { texto: "", name: "" },
                // { texto: "Tipo", name: "" },
                { texto: "", name: "" }
            ],
            columnas: [
                // { prop: "orderDetail", type: "text", format: (x) => { return x.order.code } },
                // { prop: "statusId", type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; return estados[x - 1]; } },
                { prop: "orderDetail", type: "boldText", format: (x) => { return x.order.created_at.split("T")[0]; } },
                { prop: "orderDetail", type: "text", format: (x) => { return x.fileType.name } },
                { prop: "filename", type: "text" },
                // { prop: "orderDetail", type: "text", format: (x) => { return x.order.patient.lastName } },
                // { prop: "orderDetail", type: "text", format: (x) => { return (x.order.patient.firstName[0] + x.order.patient.documentNumber.substr(x.order.patient.documentNumber.length - 3)).toUpperCase() } },
                // { prop: "orderDetail", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.order.patient.documentTypeId })[0].text } },
                // { prop: "orderDetail", type: "text", format: (x) => { return x.order.patient.documentNumber } },
                // { prop: "orderDetail", type: "text", format: (x) => { if (x.order.client === null) { return ""; } if (x.order.client.tradename) { return x.order.client.tradename; } else { return `${x.order.client.firstName} ${x.order.client.lastName}`; } } },
                // { prop: "orderDetail", type: "text", format: (x) => { if (x.order.doctor === null) { return ""; } if (x.order.hasOwnProperty("rut")) { return x.order.doctor.firstName + " " + x.order.doctor.lastName; } else { return x.order.doctor.firstName + " " + x.order.doctor.lastName; } } },
                // { prop: "branch", type: "text", format: (x) => { if (x == null) { return ""; } else { return x.name; } } },
                // { type: "status", url: "order", statusOrder: true }
                { prop: "filename", type: "boldText", format: (x) => { return 'Ver Examen' }, openVisor: true },
                // { prop: "filename", type: "text", format: (x) => { if (x.split("/")[0] == "image") { return "Imagen"; } else if (x.includes("pdf")) { return "Pdf"; } else if (x.includes("stl")) { return "Stl"; } else { return "Dicom"; } } },
                { prop: "file", type: "link", urlProp: "file", placeholder: "Descargar" }
            ]
        }
    } else {
        tabla1 = {
            endpoint: "order",
            titulos: [
                { texto: "Codigo", name: "code" },
                { texto: "Estado", name: "statusId" },
                { texto: "Fecha de Creación", name: "created_at" },
                { texto: "Nombres", name: "patient" },
                { texto: "Apellidos", name: "patient" },
                { texto: "Tipo Documento", name: "name" },
                { texto: "Numero Documento", name: "name" },
                { texto: "Clinica", name: "name" },
                { texto: "Doctor", name: "name" },
                { texto: "Sede", name: "name" }
            ],
            columnas: [
                { prop: "code", type: "link", url: "/ordenes/$id$" },
                { prop: "statusId", type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; return estados[x - 1]; } },
                { prop: "created_at", type: "link", url: "/ordenes/$id$", format: (x) => { return x.split("T")[0]; } },
                { prop: "patient", type: "text", nested: "firstName" },
                { prop: "patient", type: "text", nested: "lastName" },
                { prop: "patient", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.documentTypeId })[0].text } },
                { prop: "patient", type: "text", nested: "documentNumber" },
                { prop: "client", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.tradename; } else { return `${x.firstName} ${x.lastName}`; } } },
                { prop: "doctor", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.firstName + " " + x.lastName; } else { return x.firstName + " " + x.lastName; } } },
                { prop: "branch", type: "text", format: (x) => { if (x == null) { return ""; } else { return x.name; } } }
            ]
        }

    }
    const [tabla, setTabla] = useState(tabla1);

    //Funciones Basicas-----------------------------------------------------------------------

    //let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));

    const navigate = useNavigate();
    let location = useLocation();

    const openVisor = (tipo, archivo, id = null, obj) => {
        if (obj.orderDetail.order.comments != "") {
            setVisorComments(obj.orderDetail.order.comments);
        } else {
            setVisorComments("")
        }
        if (tipo.split("/")[0] == "image") {
            setImgSrc(archivo);
            setImgDisplay("block");
            setPdfDisplay("none");
            setVisorVisibility("visibleModal");
        }

        if (tipo == "application/pdf") {
            setImgDisplay("none");
            setPdfDisplay("block");
            setPdfSrc(archivo);
            setVisorVisibility("visibleModal");
        }

        if (tipo == "application/octet-stream" || tipo == "application/dicom" || tipo.includes("stl")) {
            setImgDisplay("none");
            setPdfDisplay("block");
            if (archivo.startsWith('[')) {
                setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
                // setPdfSrc(`http://localhost:3000/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
                // setPdfSrc(`/dist/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
            } else {
                let fileExtension = archivo.split(".");
                fileExtension = fileExtension[fileExtension.length - 1];

                switch (fileExtension) {
                    case 'stl':
                        const stlUrl = "https://stlviewr.dentyview.com?url=" + archivo;
                        setPdfSrc(stlUrl);
                        break;
                    case 'ply':
                        const plyUrl = "https://plyviewer.dentyview.com?file=" + archivo;
                        setPdfSrc(plyUrl);
                        break;
                    default:
                        setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?url=${archivo}`);
                        break;
                }

                // if (fileExtension == "stl") {
                //     let stlUrl = "https://stlviewr.dentyview.com?url=" + archivo;
                //     setPdfSrc(stlUrl);
                // } else {
                //     // setPdfSrc("https://dcmviewr.dentyview.com/tests/pacs/viewer.html?" + archivo);
                //     setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?url=${archivo}`);
                // }
            }

            setVisorVisibility("visibleModal");
        }
    }

    const closeModal = () => {
        setVisorVisibility("hiddenModal");
        setImgSrc("");
        setPdfSrc("");
    }

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }
    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    let [preSearch, setPreSearch] = useState("noBuscar");
    useEffect(() => {
        //let newModalVisibility = modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}});
        //if(newModalVisibility !== modalsVisibility){setModalsVisibility(newModalVisibility);}

        if (patientId != undefined) {
            setPreSearch(patientId);
        } else {
            setPreSearch(false);
        }

    }, [location]);

    useEffect(() => { if (preSearch != undefined) { setTabla(tabla1); } }, [preSearch])

    let estadosPacientes = [];
    if (infoUsuario.role.name !== "PATIENT") {
        estadosPacientes = [{ value: 1, label: "Activa" }, { value: 2, label: "Inactiva" }, { value: 3, label: "En Pausa" }, { value: 4, label: "Completada" }];
    }

    return (
        <>
            <div id="curtain" className={visorVisibility}>
                <div className={"modal " + visorVisibility} style={{ width: "94%" }}>
                    <div className="modalHeader">
                        <h2>Visor</h2>
                        <button className="closeModalBtn" onClick={closeModal}>x</button>
                    </div>
                    <div className="modalBody modalBgBlack" style={{ width: "100%", padding: "0px" }}>
                        <div style={{ display: imgDisplay, width: "100%", textAlign: "center" }}>
                            <div style={{
                                padding: "10px 10px",
                                color: "#fff",
                                textAlign: "left"
                            }}><b>Comentarios: </b> {visorComments}</div>
                            <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={imgSrc} />
                        </div>
                        <div style={{ display: pdfDisplay, width: "100%", height: "700px", minHeight: "300px" }}>
                            <div style={{
                                padding: "10px 10px",
                                color: "#fff",
                                textAlign: "left"
                            }}><b>Comentarios: </b> {visorComments}</div>
                            <iframe style={{ width: "100%", height: "100%" }} src={pdfSrc}></iframe><div id="test"></div>
                            {/* <div dangerouslySetInnerHTML={{ __html: `<iframe src='${pdfSrc}' />` }} /> */}
                        </div>
                    </div>
                    <div className="modalFooter">
                    </div>
                </div>
            </div>
            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
                {
                    infoUsuario.role.name !== "DOCTOR" && infoUsuario.role.name !== "CLINIC" ?
                        (
                            <Link to="/ordenes/nueva" style={infoUsuario.role.name == "PATIENT" ? { display: "none" } : { display: "block" }}>+</Link>
                        )
                        :
                        (
                            <></>
                        )
                }

            </div>


            <div className="tableContainer">
                <Tabla info={tabla} noQueries={false} noFilter={true} preSearch={preSearch} filter={estadosPacientes} forceRender={forceRender} openVisor={openVisor} />
            </div>

        </>
    );

};

export default Modulo;