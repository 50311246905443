import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { setInfoEntidad } from '../../store/infoEntidad.js'
import { setInfoUsuario } from '../../store/infoUsuario.js'
import { setInfoGeneral } from '../../store/infoGeneral.js'

let api = new Api();

const EmpleadoLogIn = ({ setIsLoggedIn }) => {
    //let appName = process.env.REACT_APP_NAME;
    let [appName, setAppName] = useState("");
    let [error, setError] = useState("");
    let [entidades, setEntidades] = useState([]);
    let [callLoginAfter, setCallLoginAfter] = useState(false);
    let tipoDocumento = useRef();
    let numeroDocumento = useRef();
    let clave = useRef();
    let entidad = useRef();
    const baseUrl = process.env.REACT_APP_GLOBAL_LOGIN_DOMAIN;

    const infoGeneral = useSelector((state) => state.infoGeneral.value);
    const infoEntidad = useSelector((state) => state.infoEntidad.value);
    const [infoGeneralCalled, setInfoGeneralCalled] = useState(false);
    const dispatch = useDispatch();


    const bringGeneralInfo = async () => {
        var info = await api.get("app/lovs/list");

        if (!info.error) {
            dispatch(setInfoGeneral(info.info));
            setInfoGeneralCalled(true);
        } else {
            setError("Hubo un problema trayendo la información General");
        }
    }

    const bringEntityInfo = async () => {
        var info = await api.get("app/" + appName);

        if (!info.error) {
            dispatch(setInfoEntidad(info.info));
            let link = document.getElementById("head-icon");
            let link2 = document.getElementById("head-icon-apple");
            link.href = info.info.icon;
            link2.href = info.info.icon;
            document.title = info.info.name;
        } else {
            setError("Hubo un problema trayendo la entidad");
        }

    }

    useEffect(() => {
        if (!infoGeneralCalled) {
            bringGeneralInfo();
        }
        let entityName = window.location.hostname.split(".")[0];

        if (entityName != baseUrl) {
            setAppName(entityName);
        }
    })

    useEffect(() => {
        if (appName != "") {
            bringEntityInfo();
        }

    }, [appName])

    useEffect(() => {
        if (callLoginAfter) {
            setCallLoginAfter(false);
            entrar();
        }
    }, [infoEntidad])






    const entrar = async () => {
        let data;
        if (appName !== "") {
            data = { documentTypeId: parseInt(tipoDocumento.current.value), documentNumber: numeroDocumento.current.value, password: clave.current.value, customerId: infoEntidad.id };
            var resp = await api.post("patient/login", data, "");

            if (!resp.error) {
                setError("");
                dispatch(setInfoUsuario(resp.info));
                localStorage.setItem('usuario', JSON.stringify(resp.info));
                localStorage.setItem('general', JSON.stringify(infoGeneral));
                localStorage.setItem('entidad', JSON.stringify(infoEntidad));
                setIsLoggedIn(true);
            } else {
                setError("Usuario no encontrado");
            }
        } else {
            data = { documentTypeId: parseInt(tipoDocumento.current.value), documentNumber: numeroDocumento.current.value, password: clave.current.value };
            var resp = await api.post("patient/login-global", data, "");

            if (!resp.error) {
                setError("");
                if (resp.info.length !== undefined) {
                    setEntidades(resp.info);
                } else {
                    setAppName(resp.info.user.customer.appName);
                    setCallLoginAfter(true);
                }
            } else {
                setError("Usuario no encontrado");
            }
        }




    }
    localStorage.setItem('was_patient', true);

    const selectAppName = () => {
        setAppName(entidad.current.value);
    }


    return (
        <>
            <h2>Iniciar Sesión</h2>
            <p className="error" style={error == "" ? { display: "none" } : { display: "block" }}>{error}</p>
            <label>Tipo Documento</label>
            <select ref={tipoDocumento}>
                {infoGeneral.documentTypeSelectOptions.map((option) => {
                    return <option key={option.value} value={option.value}>{option.text}</option>
                })}
            </select>
            <label>Numero Documento</label>
            <input type="text" ref={numeroDocumento} onKeyDown={(e) => { if (e.key === 'Enter') { entrar(); } }} />
            <label>Contraseña</label>
            <input type="password" ref={clave} onKeyDown={(e) => { if (e.key === 'Enter') { entrar(); } }} />

            <label style={entidades.length === 0 ? { display: "none" } : {}}>Entidad a la cual desea entrar</label>
            <select ref={entidad} style={entidades.length === 0 ? { display: "none" } : {}} onChange={selectAppName}>
                <option value="">Seleccione una opción</option>
                {entidades.map((entidad) => {
                    return <option value={entidad.appName}>{entidad.name}</option>;
                })}
            </select>

            <button onClick={entrar}>Entrar</button>
            <p className="olvidasteContrasena">Olvidaste tu contraseña? Haz click <Link to="../recuperarClave">aqui!</Link></p>
        </>
    );
};

export default EmpleadoLogIn;