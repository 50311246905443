import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Form from '../../components/Form.js';
import Tabla from '../../components/Tabla2.js';
import Api from '../../api.js';


const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let { id } = useParams();
    let api = new Api();


    //Configurables---------------------------------------------------------------------------
    const titulo = "Doctor";
    const icono = "fa-user-doctor";
    const display = ['ADMIN', 'EMPLOYEE'].indexOf(infoUsuario.role.name) > -1 ? false : true;
    const [modals, setModals] = useState([
        {
            titulo: "Nuevo Convenio",
            endpoint: "doctor",
            buttonText: "Crear",
            urlCall: "newAgreement",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Cliente", name: "clientId", element: "input", type: "text", required: true, isNumber: true, value: id, hidden: true }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo de Examen", name: "fileTypeId", element: "select", required: true, multiple: false, isNumber: true, options: [] },
                        { label: "Precio", name: "price", element: "input", type: "number", required: true, isNumber: true },
                    ]
                }
            ],
            dataMask: null
        }
    ]);
    const [form, setForm] = useState(
        {
            endpoint: "doctor/" + id,
            buttonText: "Editar",
            method: "PUT",
            preLoad: true,
            loadFunction: (respInfo) => {
                respInfo.clinicId = respInfo.doctorClinicDoctors.map((doc) => { return doc.clinicId });
                return respInfo;
            },
            fields: [
                {
                    label: "row", elements: [
                        { label: "Doctor", element: "subtitle" },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica Perteneciente", element: "subtitle" },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica", name: "clinicId", element: "select", multiple: true, required: false, isNumber: true, options: [] }
                    ]
                }
            ],
            dataMask: null
        });

    const tabla1 = {
        endpoint: "file-type/client-price/" + id,
        titulos: [{ texto: "Nombre", name: "firstName" }, { texto: "Precio Base", name: "price" }, { name: "status" }],
        columnas: [{ prop: "fileType", type: "link", url: "profile/$id$", nested: "name" }, { prop: "price", format: (x) => { return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }, type: "text" }, { type: "status", url: "file-type" }]
    }

    const tabla2 = {
        endpoint: 'order/admin/view2?filter={"doctorId":' + id + '}',
        titulos: [
            { texto: "Codigo", name: "code" },
            { texto: "Tipo", name: "code" },
            { texto: "Proceso", name: "code" },
            { texto: "Estado", name: "statusId" },
            { texto: "Fecha de Creación", name: "created_at" },
            { texto: "Nombres", name: "patient" },
            { texto: "Apellidos", name: "patient" },
            { texto: "Clave", name: "patient" },
            { texto: "Tipo Documento", name: "name" },
            { texto: "Numero Documento", name: "name" },
            { texto: "Clinica", name: "name" },
            { texto: "Doctor", name: "name" },
            { texto: "Sede", name: "name" },
        ],
        columnas: [
            { prop: "order", nested: 'code', type: "link", url: "/ordenes/$orderId$" },
            { prop: "fileType", nested: 'name', type: "link", url: "/ordenes/$orderId$/examen/$id$" },
            { prop: "currentActiveProcess", type: "link", url: "/ordenes/$orderId$/examen/$id$", format: (x) => { return x ? x.name : '-' } },
            { prop: "order", nested: 'statusId', type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; console.log(estados[x - 1]); return estados[x - 1]; } },
            { prop: 'order', nested: "created_at", type: "link", url: "/ordenes/$orderId$", format: (x) => { return x.split("T")[0]; } },
            { prop: "order", type: "text", format: (x) => { return x.patient.firstName } },
            { prop: "order", type: "text", format: (x) => { return x.patient.lastName } },
            { prop: "order", type: "text", format: (x) => { return (x.patient.firstName[0] + x.patient.documentNumber.substr(x.patient.documentNumber.length - 3)).toUpperCase() } },
            { prop: "order", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.patient.documentTypeId })[0].text } },
            { prop: "order", type: "text", format: (x) => { return x.patient.documentNumber } },
            { prop: "order", type: "text", format: (x) => { if (x.client === null) { return ""; } if (x.client.tradename != "") { return x.client.tradename; } else { return `${x.client.firstName} ${x.client.lastName}`; } } },
            { prop: "order", type: "text", format: (x) => { if (x.doctor === null) { return ""; } else { return x.doctor.firstName + " " + x.doctor.lastName; } } },
            { prop: "order", type: "text", format: (x) => { if (x.branch == null) { return ""; } else { return x.branch.name; } } },
        ]
    }
    const tabla22 = {
        endpoint: 'order?filter={\"doctorId\":' + id + '}',
        titulos: [
            { texto: "Codigo", name: "code" },
            { texto: "Estado", name: "statusId" },
            { texto: "Fecha de Creación", name: "created_at" },
            { texto: "Nombres", name: "patient" },
            { texto: "Apellidos", name: "patient" },
            { texto: "Clave", name: "patient" },
            { texto: "Tipo Documento", name: "name" },
            { texto: "Numero Documento", name: "name" },
            { texto: "Clinica", name: "name" },
            { texto: "Doctor", name: "name" },
            { texto: "Sede", name: "name" }
        ],
        columnas: [
            { prop: "code", type: "link", url: "/ordenes/$id$" },
            { prop: "statusId", type: "text", format: (x) => { const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]; return estados[x - 1]; } },
            { prop: "created_at", type: "link", url: "/ordenes/$id$", format: (x) => { return x.split("T")[0]; } },
            { prop: "patient", type: "text", nested: "firstName" },
            { prop: "patient", type: "text", nested: "lastName" },
            { prop: "patient", type: "text", format: (x) => { return x.firstName[0] + x.documentNumber.substr(x.documentNumber.length - 3) } },
            { prop: "patient", type: "text", format: (x) => { return infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x.documentTypeId })[0].text } },
            { prop: "patient", type: "text", nested: "documentNumber" },
            { prop: "client", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.tradename; } else { return ""; } } },
            { prop: "doctor", type: "text", format: (x) => { if (x === null) { return ""; } if (x.hasOwnProperty("rut")) { return x.firstName + " " + x.lastName; } else { return x.firstName + " " + x.lastName; } } },
            { prop: "branch", type: "text", format: (x) => { if (x == null) { return ""; } else { return x.name; } } }
        ]
    }


    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    const navigate = useNavigate();
    let location = useLocation();
    let [listaClinicas, setListaClinicas] = useState([]);

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }

    useEffect(() => {
        setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
        traerClinicas();
        traerProcedimientos();
    }, [location]);

    useEffect(() => { }, [form])

    useEffect(() => { setDoctor(); }, [listaClinicas])

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    async function traerClinicas() {
        var resp = await api.get("clinic?paginate=2000", infoUsuario.token);

        if (!resp.error) {
            setListaClinicas(resp.info.map((c) => {
                return { value: c.id, text: c.clinicUsers[0].tradename }
            }));


        }
    }

    function setDoctor() {
        let form2 = {
            endpoint: "doctor/" + id,
            buttonText: "Editar",
            method: "PUT",
            preLoad: true,
            loadFunction: (respInfo) => {
                respInfo.clinicId = respInfo.doctorClinicDoctors.map((doc) => { return doc.clinicId });
                return respInfo;
            },
            fields: [
                {
                    label: "row", elements: [
                        { label: "Doctor", element: "subtitle" },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica Perteneciente", element: "subtitle" },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica", name: "clinicId", element: "select", multiple: true, required: false, isNumber: true, options: listaClinicas }
                    ]
                }
            ],
            dataMask: null
        }
        setForm(form2);
    }

    async function traerProcedimientos() {
        var resp = await api.get("file-type", infoUsuario.token);

        if (!resp.error) {
            let tipos = resp.info.map((c) => {
                return { value: c.id, text: c.name }
            });

            let modals2 = [{
                titulo: "Nuevo Convenio",
                endpoint: "file-type/client-price",
                buttonText: "Crear",
                urlCall: "newAgreement",
                method: "POST",
                fields: [
                    {
                        label: "row", elements: [
                            { label: "Cliente", name: "clientId", element: "input", type: "text", required: true, isNumber: true, value: id, hidden: true }
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Tipo de Examen", name: "fileTypeId", element: "select", required: true, multiple: false, isNumber: true, options: tipos },
                            { label: "Precio", name: "price", element: "input", type: "number", required: true, isNumber: true },
                        ]
                    }
                ],
                dataMask: { clientId: "clientId", prices: [{ fileTypeId: "fileTypeId", price: "price" }] }
            }]
            setModals(modals2);
        }
    }

    // Aplicar Filtro Inicio
    let estadosPacientes = [];
    if (infoUsuario.role.name !== "PATIENT") {
        estadosPacientes = [{ value: 1, label: "Activa" }, { value: 2, label: "Inactiva" }, { value: 3, label: "En Pausa" }, { value: 4, label: "Completada" }];
    }

    // useEffect(() => {
    //     const fetchDatosDeTabla = async () => {
    //         try {
    //             const info = await api.get('order/admin/view2?filter={"doctorId":' + id + ', "statusId":4}', infoUsuario.token);
    
    //             if (!info.error) {
    //                 // Usa estadosPacientes para mapear los estados
    //                 const dataConEstados = info.info.map((item) => {
    //                     const estado = estadosPacientes.find((estado) => estado.value === item.statusId);
    //                     return {
    //                         ...item,
    //                         estado: estado ? estado.label : "Desconocido", // Si no encuentra el estado, usa "Desconocido"
    //                     };
    //                 });
    
    //                 console.log("Datos con estados descriptivos:", dataConEstados);
    //             } else {
    //                 console.error("Error al obtener los datos:", info.error);
    //             }
    //         } catch (error) {
    //             console.error("Error en la solicitud:", error);
    //         }
    //     };
    
    //     fetchDatosDeTabla();
    // }, []);    
    // Aplicar Filtro Final

    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}
            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            </div>

            <Form info={form} />

            <div className="pageHeading" style={display ? { display: "none" } : {}}>
                <h1><FontAwesomeIcon icon="fa-clipboard" /> Convenios</h1>
                <Link to="newAgreement" hidden={display}>+</Link>
            </div >

            <Tabla info={tabla1} noQueries={true} forceRender={forceRender} style={display ? { display: "none" } : {}} />

            <div className="pageHeading">
                <h1><FontAwesomeIcon icon="fa-file" /> Ordenes</h1>
            </div>

            {/* <Tabla info={tabla2} noQueries={false} noFilter={true} forceRender={forceRender} /> */}

            <Tabla info={tabla2} noQueries={false} noFilter={true} filter={estadosPacientes} forceRender={forceRender} />

        </>
    );

};

export default Modulo;